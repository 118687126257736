import getData from "@/__main__/get-data.mjs";
import GenericMatchListModel from "@/data-models/generic-match-list.mjs";
import TFTMatchModel from "@/data-models/tft-match.mjs";
import * as API from "@/game-tft/api.mjs";
import { PROBUILDS_MATCH_LENGTH } from "@/game-tft/constants.mjs";

async function fetchData(_routeParams, _searchParams, _state) {
  const matchIds = await getData(
    API.getProMatchIds(),
    GenericMatchListModel,
    ["tft", "proMatchIds"],
    {
      shouldFetchIfPathExists: true,
    },
  );

  const truncatedMatchIds = matchIds.slice(0, PROBUILDS_MATCH_LENGTH);

  await Promise.all(
    truncatedMatchIds.map((matchId) => {
      return getData(
        API.getMatch(matchId),
        TFTMatchModel,
        ["tft", "matches_v2", matchId],
        {
          shouldFetchIfPathExists: false,
        },
      );
    }),
  );
}

export default fetchData;
